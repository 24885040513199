import React from "react";
import "../css/RightMenu.css";

const RightMenu = (props) => {
  return (
    <div>
      <aside className={props.isActive ? 'active' : ''} style={{ top: `${props.headerHeight}px` }}>
        <ul>
        <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">事業内容</a>
          </li>
          <br />
          <li>
            <a href="/careers">採用情報</a>
          </li>
          <br />
          <li>
            <a href="/contact">お問い合わせ</a>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default RightMenu;