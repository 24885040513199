import React, { useEffect } from "react";
import "../css/Home.css";

const Home = () => {

  useEffect(() => {
    function handleResize() {
      var fontSize = parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));
      if (fontSize > 36) {
        document.body.style.fontSize = '36px';
      }
    }

    window.addEventListener('resize', handleResize);

    // クリーンアップ関数を返す
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <main>
        <section>
          <h1>私たちについて</h1>
          <p>看板製作の企画から設計、施工まで一貫して行うプロフェッショナル集団です。</p>
        </section>
        <section>
          <h2>ただ今、新たな仲間を募集中です。</h2>
          <p>
            弊社では、未経験者歓迎の建築業界で、一から技術を身につけていただける環境をご用意しております。<br />
            是非私たちと一緒に成長しませんか。
            お問い合わせについては以下の電話にてお願いいたします。<br />
          </p>
        </section>
        <section>
          <table>
            <tbody>
              <tr>
                <td><h2 style={{ fontSize: "1.4em" }}>会社名</h2></td>
                <td>株式会社あい広告</td>
              </tr>
              <tr>
                <td><h2 style={{ fontSize: "1.4em" }}>設立</h2></td>
                <td>平成23年 12月11日</td>
              </tr>
              <tr>
                <td><h2 style={{ fontSize: "1.4em" }}>代表取締役</h2></td>
                <td>松本 巌</td>
              </tr>
              <tr>
                <td><h2 style={{ fontSize: "1.4em" }}>所在地</h2></td>
                <td>
                  神戸市兵庫区和田山通1丁目2-25
                  神戸市ものづくり工場A棟106<br />
                  TEL:078-381-6939<br />
                  FAX:078-381-6933<br />
                </td>
              </tr>
              <tr>
              <td><h2 style={{ fontSize: "1.4em" }}>事業内容</h2></td>
                <td>
                  屋内外広告看板、ディスプレイの企画、設計、製作、施工<br />
                  LED、ネオン等電装工事<br />
                  カッティングシート、インクジェット出力<br />
                  内装/外装工事<br />
                  広告物申請<br />
                </td>
              </tr>
              <tr>
                <td><h2 style={{ fontSize: "1.4em" }}>資格等</h2></td>
                <td>確認中</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default Home;
