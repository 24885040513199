import React, { useEffect } from 'react';
import '../css/Footer.css';

const Footer = () => {
  useEffect(() => {
    const footer = document.querySelector('footer');
    // 初回レンダリング時にフッターを非表示に設定
    footer.style.display = 'none';

    const handleScroll = () => {
      const footer = document.querySelector('footer');
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // スクロール位置がページの最下部に達したときにフッターを表示
      if (scrollPosition + windowHeight >= document.documentElement.scrollHeight) {
        footer.style.display = 'flex';
      } else {
        footer.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <footer>
        <p>&copy; 株式会社あい広告. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;