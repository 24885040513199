import React, { useState, useEffect } from "react";
import "../css/Header.css";

const Header = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // メニューボタンがクリックされたときの処理
  const handleMenuButtonClick = () => {
    const hamburgerMenu = document.querySelector(".hamburger-menu");
    hamburgerMenu.classList.toggle("active"); // hamburger-menuのactiveクラスをトグルする

    if (hamburgerMenu.classList.contains("active")) {
      props.onHamburgerClick(); // App.jsから渡された関数を呼び出す
    } else {
      props.onCloseButtonClick(); // App.jsから渡された関数を呼び出す
    }
  };

  return (
    <header>
      {isMobile ? (
        <>
          <img
            src={process.env.PUBLIC_URL + "/logo512.png"}
            alt="Logo"
            style={{ width: "50px", margin: "5px" }}
          />
          <h1>株式会社あい広告</h1>
          <nav>
            <button
              id="menu-button"
              className="hamburger-menu"
              onClick={handleMenuButtonClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </nav>
        </>
      ) : (
        <>
          <img
            src={process.env.PUBLIC_URL + "/logo512.png"}
            alt="Logo"
            style={{ width: "50px", margin: "5px" }}
          />
          <h1>株式会社あい広告</h1>
          <nav>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">事業内容</a>
              </li>
              <li>
                <a href="/careers">採用情報</a>
              </li>
              <li>
                <a href="/contact">お問い合わせ</a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </header>
  );
};

Header.propTypes = {};

export default Header;
