import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Careers from './components/Careers';
import Contact from './components/Contact';
import Footer from './components/Footer';
import RightMenu from './components/RightMenu';

function App() {
  const appRef = useRef();
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(50);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const headerHeight = document.querySelector('header').offsetHeight;
    setHeaderHeight(headerHeight);
    appRef.current.style.paddingTop = `${headerHeight}px`;

    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);

      // 画面サイズが768以上になったときにRightMenuを非表示にする
      if (!mobile && showRightMenu) {
        handleCloseButtonClick();
      }
    };

    window.addEventListener('resize', handleResize);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showRightMenu]);

  const handleHamburgerClick = () => {
    setShowRightMenu(true);
  };

  const handleCloseButtonClick = () => {
    setShowRightMenu(false);
  };

  return (
    <Router>
      <div className="App" ref={appRef}>
        <Header onHamburgerClick={handleHamburgerClick} onCloseButtonClick={handleCloseButtonClick} />
        {isMobile && showRightMenu && <RightMenu isActive={showRightMenu}  headerHeight={headerHeight} />}
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;