import React, { useEffect } from "react";
import "../css/About.css";

const About = () => {

  useEffect(() => {
    function handleResize() {
      var fontSize = parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));
      if (fontSize > 36) {
        document.body.style.fontSize = '36px';
      }
    }

    window.addEventListener('resize', handleResize);

    // クリーンアップ関数を返す
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <main>
        <section>
          <h1>私たちについて</h1>
          <p>AboutUs</p>
        </section>
      </main>
    </div>
  );
};

export default About;
